<div class="container">
    <section>
        <h1 class="mt-3 mb-3 h1 text-center">Información que contiene el certificado</h1>
        <hr>
        <div class="container px-4 text-center">
            <div class="row gx-5">
                <div class="col">
                    <div class="p-3">
                        <img loading="lazy" src="https://www.blita.com/hs-fs/hubfs/abogado%20blog.png?width=1298&name=abogado%20blog.png"
                            class="img_about_cert" alt="...">
                    </div>
                </div>
                <div class="col">
                    <div class="p-3">
                        <div class="about_info_cer text-align:left">
                            <ul style="list-style-type:disc; text-align: start;">
                                <li>Identificación de propietario(s) actual(es) y anteriores.</li>
                                <li>Descripción del inmueble (casa, apartamento, finca, lote, propiedad horizontal)</li>
                                <li>Antigüedad del predio</li>
                                <li>Situación jurídica</li>
                                <li>Modo de adquisición del predio (compraventa, donación, sucesión)</li>
                                <li>Dirección</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mb-5 mt-5 text-center" id="button-buy">
            <a routerLink="/buy"><button class="btn btn-buy" type="button" name="button">Comprar Certificado</button></a>
        </div>
        <hr>
    </section>
    <!-- Razones búsqueda sin resultados -->
    <section>
        <div>
            <h1 class="h1 text-center">Razones por las que NO tienes resultados en la búsqueda de tu certificado</h1>
            <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab"
                tabindex="0">
                <ul style="list-style-type:disc; text-align: start;">
                    <li>No se suministra correctamente el número de matricula o alguno de los datos de búsqueda.</li>
                    <li>Cuando sobre el inmueble se encuentre un trámite en proceso en la oficina de instrumentos
                        públicos (Supernotariado).</li>
                </ul>
            </div>
        </div>
        <hr>
    </section>
    <!-- Acerca de los certificados de no propiedad -->
    <section>
        <div>
            <h1 class="h1 text-center">Acerca de los certificados de No propiedad</h1>
            <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab"
                tabindex="0">
                <p>El certificado de no propiedad, también conocido como certificado de no vivienda, hace constar que la
                    persona no es
                    propietaria de bienes inmuebles a nivel nacional.
                    Este certificado generalmente es requerido cuando la persona desea acceder a programas del Gobierno
                    nacional para
                    obtener subsidios o acceder a programas de vivienda. Asimismo es útil para cualquier trámite que
                    requiera comprobar la
                    inexistencia de propiedad.</p>
            </div>
        </div>
        <div>
            <h1 class="h1 text-center">Consulta índice de propietarios</h1>
            <div class="container px-4 text-center">
                <div class="row gx-5">
                    <div class="col">
                        <div class="p-3">
                            <div class="about_info_cer text-align:left">
                                <ul style="list-style-type:disc; text-align: start;">
                                    <li>El índice de propietarios es una consulta que permite identificar las
                                        propiedades vinculadas al titular (persona o entidad). En la consulta se
                                        obtiene el número de matricula y datos de ubicación.</li>
                                    <li>Con estos datos se solicita directamente el Certificado de tradición y
                                        libertad aquí</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="p-3">
                            <img loading="lazy" src="https://www.blita.com/hs-fs/hubfs/abogado%20blog.png?width=1298&name=abogado%20blog.png"
                                class="img_query_ind_pro" alt="...">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <h1 class="h1 text-center">Acerca de la Superintendencia de Notariado y Registro</h1>
            <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab"
                tabindex="0">
                <p>La Superintendencia de Notariado y Registro ejercerá la orientación, inspección, vigilancia y control
                    de
                    los servicios públicos que prestan los Notarios y los Registradores de Instrumentos Públicos.</p>
            </div>
            <div class="mb-5 mt-5 text-center" id="button-buy">
                <a routerLink="/buy"><button class="btn btn-buy" type="button" name="button">Comprar Certificado</button></a>
            </div>
        </div>
        <hr>
    </section>
    <!-- Banners informativos -->
    <section>
        <div class="container px-4 text-center">
            <div class="row gx-5">
                <div class="col">
                    <div class="p-3">
                        <div id="carouselExampleIndicators" class="carousel slide">
                            <div class="carousel-indicators">
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0"
                                    class="active" aria-current="true" aria-label="Slide 1" name="button"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1"
                                    aria-label="Slide 2" name="button"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2"
                                    aria-label="Slide 3" name="button"></button>
                            </div>
                            <div class="carousel-inner">
                                <div class="carousel-item active">
                                    <img loading="lazy" src="https://www.blita.com/hs-fs/hubfs/abogado%20blog.png?width=1298&name=abogado%20blog.png"
                                        class="d-block w-100" alt="...">
                                </div>
                                <div class="carousel-item">
                                    <img loading="lazy" src="https://www.blita.com/hs-fs/hubfs/abogado%20blog.png?width=1298&name=abogado%20blog.png"
                                        class="d-block w-100" alt="...">
                                </div>
                                <div class="carousel-item">
                                    <img loading="lazy" src="https://www.blita.com/hs-fs/hubfs/abogado%20blog.png?width=1298&name=abogado%20blog.png"
                                        class="d-block w-100" alt="...">
                                </div>
                            </div>
                            <button class="carousel-control-prev" type="button" name="button"
                                data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span class="visually-hidden">Previous</span>
                            </button>
                            <button class="carousel-control-next" type="button" name="button"
                                data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                <span class="visually-hidden">Next</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="p-3">
                        <div id="carouselExampleIndicators2" class="carousel slide">
                            <div class="carousel-indicators">
                                <button type="button" data-bs-target="#carouselExampleIndicators2" data-bs-slide-to="0"
                                    class="active" aria-current="true" aria-label="Slide 1" name="button"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators2" data-bs-slide-to="1"
                                    aria-label="Slide 2" name="button"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators2" data-bs-slide-to="2"
                                    aria-label="Slide 3" name="button"></button>
                            </div>
                            <div class="carousel-inner">
                                <div class="carousel-item active">
                                    <img loading="lazy" src="https://www.blita.com/hs-fs/hubfs/abogado%20blog.png?width=1298&name=abogado%20blog.png"
                                        class="d-block w-100" alt="...">
                                </div>
                                <div class="carousel-item">
                                    <img loading="lazy" src="https://www.blita.com/hs-fs/hubfs/abogado%20blog.png?width=1298&name=abogado%20blog.png"
                                        class="d-block w-100" alt="...">
                                </div>
                                <div class="carousel-item">
                                    <img loading="lazy" src="https://www.blita.com/hs-fs/hubfs/abogado%20blog.png?width=1298&name=abogado%20blog.png"
                                        class="d-block w-100" alt="...">
                                </div>
                            </div>
                            <button class="carousel-control-prev" type="button" name="button"
                                data-bs-target="#carouselExampleIndicators2" data-bs-slide="prev">
                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span class="visually-hidden">Previous</span>
                            </button>
                            <button class="carousel-control-next" type="button" name="button"
                                data-bs-target="#carouselExampleIndicators2" data-bs-slide="next">
                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                <span class="visually-hidden">Next</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Info banners -->
        <div>
            <h1 class="h1 text-center">Conoce acerca del certificado de tradición y libertad</h1>
            <div class="container px-4 text-center">
                <div class="row gx-5">
                    <div class="col">
                        <div class="p-3">
                            <div class="about_info_cer text-align:left">
                                <ul style="list-style-type:disc; text-align: start;">
                                    <li>Es un documento emitido por la Superintendencia de Notariado y registro
                                        (Supernotariado) que indica la información relacionada con un predio (inmueble)
                                        de forma cronológica desde su constitución hasta el momento en que se expide el
                                        certificado.</li>
                                    <li>Para consultarlo requieres el número de matricula inmobiliaria que identifica el
                                        predio.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="p-3">
                            <img loading="lazy" src="https://www.blita.com/hs-fs/hubfs/abogado%20blog.png?width=1298&name=abogado%20blog.png"
                                class="img_query_ind_pro" alt="...">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <!-- Certificados de No propiedad -->
        <div>
            <h1 class="h1 text-center">Certificados de No propiedad</h1>
            <div class="container px-4 text-center">
                <div class="row gx-5">
                    <div class="col">
                        <div class="p-3">
                            <img loading="lazy" src="https://www.blita.com/hs-fs/hubfs/abogado%20blog.png?width=1298&name=abogado%20blog.png"
                                class="img_query_ind_pro" alt="...">
                        </div>
                    </div>
                    <div class="col">
                        <div class="p-3">
                            <div class="about_info_cer text-align:left">
                                <ul style="list-style-type:disc; text-align: start;">
                                    <li>El certificado de no propiedad, también conocido como certificado de no
                                        vivienda, hace constar que la persona no es propietaria de bienes inmuebles a
                                        nivel nacional.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mb-5 mt-5 text-center" id="button-buy">
            <a routerLink="/buy"><button class="btn btn-buy" type="button" name="button">Comprar Certificado</button></a>
        </div>
    </section>
</div>
