<!-- @if (showLogin) {
  <section class="section">
    <form [formGroup]="form">
      <label for="pass">Contraseña</label>
      <input formControlName="pass" type="text" id="pass">
      <app-btn-normal
        (click)="verified()"
        [contentBtn]="'Verificar'"
      ></app-btn-normal>
    </form>
  </section>
} -->
<router-outlet></router-outlet>
