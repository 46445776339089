import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AboutComponent } from './components/about/about.component';
import { FrquComponent } from './components/frqu/frqu.component';
import { RecoverCertComponent } from './website/components/recover-cert/recover-cert.component';
import { TransactionStatusComponent } from './components/transaction-status/transaction-status.component';
import { RedeemCodesComponent } from './components/redeem-codes/redeem-codes.component';
import { PoliciesComponent } from './components/policies/policies.component';
import { TermsComponent } from './components/terms/terms.component';
import { ListComponent } from './blog/list/list.component';
import { PostComponent } from './blog/post/post.component';
import { ErrorComponent } from './components/error/error.component';

import { IsAutenticateGuard } from './guards/is-autenticate.guard';
import { IsAdminGuard } from './guards/is-admin.guard';

const routes: Routes = [
  {
    path:'',
    loadChildren: () => import("../app/website/website.module").then(m => m.WebsiteModule)
  },
  {
    path:'auth',
    loadChildren: () => import("../app/auth/auth.module").then(m => m.AuthModule)
  },
  {
    path:'colaborador',
    canActivate: [IsAutenticateGuard],
    loadChildren: () => import("../app/collaborator/collaborator.module").then(m => m.CollaboratorModule)
  },
  {
    path:'admin',
    canActivate: [IsAutenticateGuard, IsAdminGuard],
    loadChildren: () => import("../app/administrator/administrator.module").then(m => m.AdministratorModule)
  },
  {
    path:'proceso-pago',
    loadChildren: () => import("../app/payment/payment.module").then(m => m.PaymentModule)
  },
  { path: 'estado-transaccion/:id', component: TransactionStatusComponent },
  { path: 'about', component: AboutComponent },
  { path: 'preguntas-frecuentes', component: FrquComponent },
  { path: 'recuperar-certificado', component: RecoverCertComponent },
  { path: 'terminos-y-condiciones', component: TermsComponent },
  { path: 'politicas-privacidad', component: PoliciesComponent },
  { path: 'blog', component: ListComponent },
  { path: 'blog/post/:titleUrl/:postId', component: PostComponent },
  { path: 'redimir-codigo', component: RedeemCodesComponent },
  { path: '**', component: ErrorComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
