import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class IsAutenticateGuard implements CanActivate {

  constructor(
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: any
  ) {}

  canActivate() {
    let animalToken = this.getItemsLS('animal')
    let infoUser = this.getItemsLS('infoPerson')
    if (animalToken && infoUser) return true;
    this.router.navigate(['/'])
    return false;
  }

  getItemsLS(item: string) {
    if (isPlatformBrowser(this.platformId)) {
      return localStorage.getItem(item)
    }
    return '';
  }

}
