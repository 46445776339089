import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { timeout, retry } from 'rxjs';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InfoTransactionService {
  url = environment.apiUrlV2.concat('/payment-process')

  private http = inject(HttpClient)

  getInfo(id: string) {
    return this.http
    .get(this.url.concat(`/transaction/${id}`))
  }

  createTransaction(body: any) {
    return this.http
    .post(this.url.concat('/pay'), body)
  }

}
