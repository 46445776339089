import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PinValidateService {

  url = environment.apiUrl

  private http = inject(HttpClient)

  validatePin(code: string) {
    return this.http.get(`${this.url}redeem/validate/${code}`)
  }

}
