import { Component, OnInit, inject, signal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { InfoTransactionService } from '../services/info-transaction.service';
import { AlertService } from 'src/app/services/alert.service';

@Component({
  selector: 'app-transaction-status',
  templateUrl: './transaction-status.component.html',
  styleUrls: ['./transaction-status.component.scss']
})
export class TransactionStatusComponent implements OnInit {

  infoTransaction = signal<any>({})
  showOutHour = signal(false);
  showWeekend = signal(false);
  states = signal({
    10: 'Pendiente',
    14: 'Aprobado',
    12: 'Fallido',
    9: 'Rechazado'
  })
  meansPay = signal({
    'pse': 'PSE',
    'code': 'Código redimible',
    'card': 'Tarjeta de crédito'
  })

  private route = inject(ActivatedRoute)
  private router = inject(Router)
  private infoTransactionInfo = inject(InfoTransactionService)
  private alertService = inject(AlertService)

  ngOnInit() {
    let idTran = this.route.snapshot.params['id']
    if (idTran[0] === '$') idTran = idTran.substr(1)
    this.getInfoTransaction(idTran)
  }

  getInfoTransaction(id: string) {
    this.infoTransactionInfo.getInfo(id)
    .subscribe((data: any) => {
      if (data) {
        this.infoTransaction.set(data.request)
        this.outHour()
      } else this.router.navigate(['/'])
    }, err => {
      this.alertService.alert('error', err.error.err)
      this.router.navigate(['/'])
    })
  }

  outHour() {
    let showMessage = false;
    const actualDay = new Date(this.infoTransaction().created_at);
    actualDay.getHours() >= 19 ? showMessage = true : showMessage = false;
    actualDay.getDay() >= 1 && actualDay.getDay() <= 6
      ? this.showWeekend.set(false)
      : this.showWeekend.set(true);
    if (this.showWeekend && actualDay.getHours() >= 15) this.showWeekend.set(false);
    this.showOutHour.set(showMessage)
  }

}
