<app-header [showTitle]="false"></app-header>

<main class="general">
  <p class="general-title">TÉRMINOS Y CONDICIONES DE USO DE LA PLATAFORMA</p>
  <p>
    <span>Megapagos.co S.A.S</span>, nit 901.406.309-2 (en adelante <span>MEGAPAGOS</span>) es una empresa encargada de comercializar y facilitar servicios a través de una plataforma privada.
  </p>
  <p>
    <span>MEGAPAGOS</span> declara que no pertenece orgánicamente a la Superintendencia de Notariado y Registro.
  </p>
  <ol>
    <li>
      <span>Generalidades</span>
      <ul>
        <li>
          El presente documento incluye los términos y condiciones de uso de la plataforma bajo los cuales <span>MEGAPAGOS</span> y el <span>USUARIO</span> rigen su relación.
        </li>
        <li>
          Con la aceptación de estos Términos y Condiciones el <span>USUARIO</span> podrá usar libremente la plataforma de <span>MEGAPAGOS</span> por medio de la cual se comercializan diversos productos y/o servicios online.
        </li>
        <li>
          <span>MEGAPAGOS</span>, en cumplimiento de sus funciones, creará y tendrá a disposición todos los mecanismos para la recolección y tratamiento de datos (<a routerLink="/politicas-privacidad">Política de Tratamiento de Datos</a>).
        </li>
        <li>
          <span>MEGAPAGOS</span> no garantiza el acceso y uso continuado o ininterrumpido de los productos y/o servicios que se comercializan a través de <span>MEGAPAGOS</span>, debido a dificultades técnicas del proveedor, fallas de Internet o por cualquier otra circunstancia ajena a <span>MEGAPAGOS</span>.
        </li>
        <li>
          El <span>USUARIO</span>, declara expresamente que reconoce y acepta el contenido de los presentes términos y condiciones, así como cualquier actualización que se haga sobre los mismos.
        </li>
        <li>
          <span>MEGAPAGOS</span> podrá acceder, almacenar y tratar la información para fines administrativos, estadísticos, prestación de servicios en sus plataformas, control de calidad y atención al cliente. (<a routerLink="/politicas-privacidad">Política de Tratamiento de Datos</a>)
        </li>
        <li>
          Cualquier persona que no acepte este acuerdo, el cual tiene carácter obligatorio y vinculante, deberá abstenerse de utilizar los Servicios de <span>MEGAPAGOS</span>.
        </li>
        <li>
          El acceso y uso a la plataforma, sus servicios y funcionalidades no otorga a ninguna clase de <span>USUARIO</span> la adquisición de gravámenes, derechos o propiedad, incluida la propiedad intelectual sobre los elementos de la misma.
        </li>
      </ul>
    </li>
    <li>
      <span>Acerca de el/los producto(s) y/o servicio(s)</span>
      <ul>
        <li>
          El valor cobrado se establece conforme a la tarifa de la entidad proveedora (SNR) y el cobro por servicio y procesamiento del pago por parte de <span>MEGAPAGOS</span>.
        </li>
        <li>
          Las URL operadas por <span>MEGAPAGOS</span> son páginas privadas, es decir, no pertenecen directamente a ninguna entidad gubernamental.
        </li>
        <li>
          <span>MEGAPAGOS</span> garantiza la seguridad de los datos suministrados en el pago de los servicios adquiridos por los <span>USUARIOS</span>, mediante el cumplimiento de las normas PCI por parte de la pasarela de pagos procesadora de las transacciones.
        </li>
        <li>
          El <span>USUARIO</span> debe conocer los datos de búsqueda del certificado o consulta requeridos. <span>MEGAPAGOS</span> no asume responsabilidad sobre los errores de digitación del <span>USUARIO</span>.
        </li>
        <li>
          <span>MEGAPAGOS</span> no es responsable de la veracidad de la información que contiene los documentos adquiridos a través de la plataforma.
        </li>
        <li>
          El <span>USUARIO</span>, a través de la plataforma podrá, entre otras, obtener consulta de índice de propietarios, certificados de no propiedad y Certificados de Tradición y Libertad.
        </li>
        <li>
          El <span>USUARIO</span> tiene acceso a la recuperación del Certificado de tradición y libertad adquirido durante los últimos 30 días calendario anteriores al día de la solicitud de recuperación.
        </li>
        <li>
          <span>MEGAPAGOS</span> habilita como medios de pago de los servicios PSE y tarjeta de crédito o débito.
        </li>
        <li>
          Para la generación de un Certificado, el <span>USUARIO</span> debe indicar, entre otros, tipo y número de documento de identidad, nombres, número de celular y correo electrónico al cual se enviará la copia del Certificado solicitado. Si no encuentra el correo electrónico con el certificado, puede buscarlo en la carpeta de correos no deseados, o comunicarse con <span>MEGAPAGOS</span> a través del proceso de recuperar certificado.
        </li>
        <li>
          La información solicitada para la expedición del Certificado será compartida con otras páginas web, con fines exclusivos para la generación y envío del Certificado, como también para todo lo relacionado con este proceso.
        </li>
        <li>
          El envío del Certificado por correo electrónico se realizará expresamente a la dirección de correo suministrada por el usuario, por tanto, <span>MEGAPAGOS</span> no se hace responsable por el envío de correo electrónico a direcciones erróneas consignadas por el <span>USUARIO</span>.
        </li>
        <li>
          La valores cobrada por <span>MEGAPAGOS</span> puede variar en cualquier momento, sin previo aviso y sin que esto genere ninguna responsabilidad para <span>MEGAPAGOS</span> , bastará con la publicación del mismo al momento de aceptar la transacción.
        </li>
        <li>
          El <span>USUARIO</span> podrá realizar la validación del Certificado generado, en el enlace previsto por la Superintendencia de Notario y Registro para este fin, <a href="https://certificados.supernotariado.gov.co/certificado/external/validation/validate.snr" target="_blank" rel="noopener noreferrer">https://certificados.supernotariado.gov.co/certificado/external/validation/validate.snr</a>, ingresando el PIN que aparece en la parte superior del Certificado.
        </li>
        <li>
          La información personal se almacena y procesa de acuerdo con la legislación actual de protección de datos personales, la cual podrá ser consultada en <a href="https://www.megared.co/policies" target="_blank" rel="noopener noreferrer">https://www.megared.co/policies</a>.
        </li>
        <li>
          Los <span>USUARIOS</span> a través de la página oficial de la Superintendencia de Notariado y Registro (www.supernotariado.gov.co), podrán acceder a los mismos servicios suministrados por <span>MEGAPAGOS</span>, sin cargo alguno.
        </li>
      </ul>
    </li>
    <li>
      <span>Devoluciones o reembolsos</span>
      <p>
        El <span>USUARIO</span> reconoce que, en la generación de los servicios, puede presentarse fallas, no necesariamente generadas en la plataforma operada por <span>MEGAPAGOS</span>.
      </p>
      <p>
        En caso de presentarse fallas, es decir, que el documento solicitado no ha sido emitido por error en los datos suministrados, matrícula en trámite, matrícula no disponible, entre otros; independiente el medio de pago utilizado por el <span>USUARIO</span> para finalizar el proceso de compra, el <span>USUARIO</span> recibirá un CÓDIGO redimible por el monto del valor pagado o del certificado específico que no fue emitido. Es decir, no se realiza devolución de dinero a cuentas bancarias del USUARIO.
      </p>
      <p>
        Este CÓDIGO se redime en un nuevo proceso de compra en nuestra página https://certificadostradicionylibertad.co, en la sección donde se selecciona el medio de pago está disponible la opción REDIMIR CÓDIGO
      </p>
      <p>
        El código enviado por <span>MEGAPAGOS</span> podrá ser usado una (1) única vez en una transacción exitosa y tendrá validez para redimir hasta el monto pagado.
      </p>
    </li>
    <li>
      <span>Modificaciones a los Términos y Condiciones</span>
      <p>
        <span>MEGAPAGOS</span> se reserva el derecho de modificar los presentes Términos y Condiciones en cualquier momento, dichos Términos y Condiciones serán efectivos una vez sean publicados y es responsabilidad exclusiva del usuario consultarlos cada vez que use la plataforma.
      </p>
    </li>
    <li>
      <span>Ley Aplicable y Jurisdicción </span>
      <p>
        Todo lo relacionado con el cumplimiento y aplicación de los presentes términos y condiciones, se sujetará a las normas legales civiles y comerciales de la República de Colombia, en especial lo establecido en Estatuto del Consumidor Ley 1480 de 2011, normas que lo modifiquen o adicionen.
      </p>
      <p>
        En caso de surgir controversias por concepto de la ejecución, prestación o terminación de los servicios prestados por <span>MEGAPAGOS</span>, estas pueden ser resueltas de común acuerdo, en primera instancia, mediante los mecanismos de Mediación o Conciliación (MASC Mecanismo Alternativo de solución de Conflictos).
      </p>
    </li>
  </ol>
</main>
