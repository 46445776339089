<app-header [showTitle]="false"></app-header>
<main>
  <section class="content">
    @if (infoPostShow().length) {
      <h1 class="content__title">{{ infoPostShow()[1]?.title }}</h1>
      <p class="content__date">{{ infoPostShow()[1]?.date | date:'medium' }}</p>
      <p class="content__subtitle">{{ infoPostShow()[1]?.introduction }}</p>
      <div class="content__img">
        <img width="350" height="350" [ngSrc]="infoPostShow()[1]?.img" [alt]="infoPostShow()[1]?.descriptionImg">
      </div>
    }
    <div id="paragraphs" class="content__parragraph"></div>
    <div class="content__footer">
      <div>
        <a href="https://www.instagram.com/certificadostradicionylibertad/" target="_blank" rel="noopener noreferrer">
          <img src="../../../assets/icons/Unicolor/instagram.svg" alt="Icono de Instagram">
        </a>
        <a href="https://www.facebook.com/profile.php?id=100084390038338" target="_blank" rel="noopener noreferrer">
          <img src="../../../assets/icons/Unicolor/facebook.svg" alt="Icono de Facebook">
        </a>
      </div>
      <a routerLink="/">Comprar certificado</a>
    </div>
  </section>
  @if (!infoPostShow().length) {
    <section class="content">
      <p class="skeleton-effect"></p>
      <p class="skeleton-effect"></p>
      <p class="skeleton-effect"></p>
      <p class="skeleton-effect"></p>
      <p class="skeleton-effect"></p>
      <p class="skeleton-effect"></p>
      <p class="skeleton-effect"></p>
      <p class="skeleton-effect"></p>
      <p class="skeleton-effect"></p>
      <p class="skeleton-effect"></p>
      <p class="skeleton-effect"></p>
      <p class="skeleton-effect"></p>
    </section>
  }

  <section class="other-articles">
    <div class="other-articles__div">
      Otros artículos de interés
    </div>
    <section class="other-articles__article">
      <article *ngFor="let article of arrayShortImages()" (click)="onPost(article[1].titleUrl, article[0])">
        <img [src]="article[1]['img']" [alt]="article[1]['descriptionImg']">
        <p>{{ article[1]['title'] }}</p>
        <p>
          {{ article[1]['introduction'].slice(0, 100) }}
          <span *ngIf="article[1].introduction.length > 100">...</span>
        </p>
      </article>
    </section>
  </section>
</main>
<app-footer></app-footer>
