import { Injectable, PLATFORM_ID, Inject, inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';
import { CanActivate } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class IsAdminGuard implements CanActivate {

  private router = inject(Router)
  constructor(
    @Inject(PLATFORM_ID) private platformId: any
  ) {}

  canActivate() {
    let isAdmin = isPlatformBrowser(this.platformId)
      ? localStorage.getItem('typeAuth')
      : '';
    if (isAdmin && isAdmin == 'SA') return true;
    this.router.navigate(['/'])
    return false;
  }

}
